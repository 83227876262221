<template>
  <v-container>
    <v-row class="text-center home-page">
      <v-col cols="12" xs="12" class="logo">
        <img src="@/assets/images/bionix.svg" alt="logo" />
      </v-col>
      <v-col cols="12" xs="12" v-if="articles">
        <h1 class="text-h5 text-md-h4" id="page-title">
          {{ articles.name }}
        </h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        class="d-flex my-2 px-5"
        :class="
          $vuetify.breakpoint.smAndDown ? 'justify-center' : 'justify-end'
        "
        cols="12"
        xs="12"
        sm="5"
      >
        <div class="product-image" v-if="articles">
          <img :src="articles.img" alt="product_image" />
        </div>
      </v-col>
      <v-col
        class="my-6 px-7 product-dsc"
        cols="12"
        xs="12"
        sm="7"
        v-if="articles"
      >
        <div class="product-text">
          <p>
            {{ articles.description }}
          </p>
        </div>
        <div class="product-details">
          <p><b>Product name : </b> {{ articles.name }}</p>
          <p><b>Collection : </b> {{ articles.season }}</p>
          <p><b>Colour : </b> {{ articles.color }}</p>
          <p><b>Size : </b> {{ articles.sizes }}</p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "home",
  mounted() {
    const id = this.$route.params.id;
    this.fetchArticles(id);
  },
  methods: {
    ...mapActions({
      fetchArticles: "dashboard/fetchArticles",
    }),
  },
  computed: {
    ...mapGetters({
      articles: "dashboard/getArticle",
    }),
  },
};
</script>
